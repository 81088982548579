/** @jsx jsx */
import { Text, jsx } from 'theme-ui';
import { graphql } from 'gatsby';
import { object, shape } from 'prop-types';

const Alerts = ({ data: { allContentfulAlert } }) => {
  const alertMessage = allContentfulAlert.nodes[0];
  return (
    alertMessage &&
    alertMessage.publish && (
      <Text
        sx={{
          bg: 'alert',
          mt: [0, 0],
          px: 3,
          py: 2,
          fontSize: 2,
          textAlign: 'center',
          whiteSpace: 'pre-line',
        }}
      >
        {alertMessage.message.message}
      </Text>
    )
  );
};

Alerts.displayName = 'Alerts';
Alerts.propTypes = {
  data: shape({
    allContentfulAlert: object,
  }),
};

export default Alerts;

export const query = graphql`
  fragment Alerts on Query {
    allContentfulAlert(filter: { node_locale: { eq: $locale } }) {
      nodes {
        message {
          message
        }
        publish
      }
    }
  }
`;
