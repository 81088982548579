/** @jsx jsx */

import styled from '@emotion/styled';
import { Heading, jsx } from 'theme-ui';

import { breakpoints, colors, spacing } from '../../utils/styles';

export const TextContainer = styled(`div`)`
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  padding: ${spacing.md}px;
  width: 100%;

  @media (min-width: ${breakpoints.desktop}px) {
    padding-bottom: 100px;
  }
`;

// eslint-disable-next-line react/prop-types
const H1 = ({ className, children }) => (
  <Heading className={className} as="h1" variant="h1">
    {children}
  </Heading>
);

// eslint-disable-next-line react/prop-types
const H2 = ({ className, children }) => (
  <Heading className={className} as="h2" variant="h2">
    {children}
  </Heading>
);

// eslint-disable-next-line react/prop-types
const H3 = ({ className, children }) => (
  <Heading className={className} as="h3" variant="h3">
    {children}
  </Heading>
);

// eslint-disable-next-line react/prop-types
const H4 = ({ className, children }) => (
  <Heading className={className} as="h4" variant="h4">
    {children}
  </Heading>
);

export const UnorderedList = styled('ul')`
  color: ${colors.text};
  margin: 1rem 0 0;
  padding-left: 2rem;

  li {
    margin: 0 0 0.25rem;
  }

  a {
    color: ${colors.text};
  }
`;

export { H1, H2, H3, H4 };
