/** @jsx jsx */

import styled from '@emotion/styled';
import { FaFacebook } from 'react-icons/fa';
import { FormattedMessage, Link } from 'gatsby-plugin-react-intl';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import { shape, string } from 'prop-types';

import { H3 } from '../components/shared/Typography';

const LocationCardWrapper = styled.div`
  margin: 1.8rem 0;
  display: grid;
  grid-template-columns: 16rem minmax(18rem, 50rem);
  column-gap: 1.6rem;
  justify-content: center;

  > img {
    max-width: 16rem;
  }

  > div > h3 {
    font-weight: 400;
    margin-top: 0;
  }

  > div > p {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 400px) {
    display: block;
    text-align: center;

    > img {
      margin-top: 2.4rem;
    }
  }
`;

const Dot = styled.span`
  margin: 0 0.8rem;

  &::after {
    content: '•';
  }
`;

const FacebookLink = styled.a`
  color: #3b5998;
  font-size: 1.6rem;
  vertical-align: middle;
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

const LocationCard = ({
  location: { address, facebookUrl, heroImage, bookUrl, mapsUrl, phoneNumber, slug, title },
}) => {
  return (
    <LocationCardWrapper>
      <GatsbyImage image={heroImage.gatsbyImageData} alt={`Lockcha ${title}`} />
      <div>
        <Link to={`/locations/${slug}/`}>
          <H3 sx={{ mt: [0, 0] }}>{title}</H3>
        </Link>
        <p>
          {address}
          <br />
          <NoWrap>
            <a href={bookUrl} target="_blank" rel="noreferrer">
              <FormattedMessage id="location.bookNow" />
            </a>
            &nbsp;/&nbsp;
            <FormattedMessage
              id="location.openInGoogleMaps"
              values={{
                gmap: (
                  <a href={mapsUrl} target="_blank" rel="noreferrer">
                    Google Maps
                  </a>
                ),
              }}
            />
          </NoWrap>
        </p>
        <p>
          <FacebookLink href={facebookUrl} title={`${title} Facebook page`}>
            <FaFacebook />
          </FacebookLink>
          <Dot />
          {phoneNumber}
        </p>
      </div>
    </LocationCardWrapper>
  );
};

LocationCard.propTypes = {
  location: shape({
    address: string,
    facebookUrl: string,
    mapsUrl: string,
    slug: string,
    phoneNumber: string,
    title: string,
  }),
};

export default LocationCard;

export const query = graphql`
  fragment Location on ContentfulLocation {
    address
    bookUrl
    facebookUrl
    mapsUrl
    phoneNumber
    slug
    title
    heroImage {
      gatsbyImageData(width: 240)
    }
  }
`;
