import PropTypes from 'prop-types';
/** @jsx jsx */
import React from 'react';
import { Helmet } from 'react-helmet';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';

import Alerts from '../../components/Alerts';
import Link from '../../components/shared/Link';
import LocationCard from '../../components/LocationCard';
import { H1 } from '../../components/shared/Typography';

const LocationsPage = ({ data, location }) => {
  const { title, description } = data.mdx.frontmatter.meta;
  const locations = data.allContentfulLocation.nodes;

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:url" content={`https://www.lockcha.com${location.pathname}`} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
      </Helmet>
      <Alerts data={data} />
      <MDXRenderer
        components={{
          a: Link,
          // eslint-disable-next-line react/display-name, react/prop-types
          h1: ({ children }) => <H1 sx={{ textAlign: 'center' }}>{children}</H1>,
          // eslint-disable-next-line react/display-name, react/prop-types
          wrapper: ({ children }) => (
            <article sx={{ mb: 3, mx: 'auto', px: [2, 3], maxWidth: '96rem' }}>{children}</article>
          ),
        }}
      >
        {data.mdx.body}
      </MDXRenderer>
      {locations.map((l) => (
        <LocationCard key={l.title} location={l} />
      ))}
    </React.Fragment>
  );
};

export const query = graphql`
  query LocationsPageQuery($locale: String) {
    mdx(frontmatter: { handle: { eq: "locations" }, locale: { eq: $locale } }) {
      frontmatter {
        meta {
          title
          description
        }
      }
      body
    }
    allContentfulLocation(
      sort: { fields: [displayOrder] }
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        ...Location
      }
    }
    ...Alerts
  }
`;

LocationsPage.displayName = 'LocationPage';

LocationsPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default LocationsPage;
